import { React, useEffect, useState } from "react";
import Header from "./Header";
import Chart from "./Charts";
import Chartt from "./ChartWYS";
// import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { Wys_address, base_url, localhost_api } from "./web3/config";
import { Toaster, toast } from "react-hot-toast";
import {
  idToAddres,
  stakeWYS,
  wysTOkenApprove,
  getWYSToUSDAmt,
  amtApprove,
  allowance,
  claimwys,
  claimWYSAmt,
  wysPrice,
  getBalanceUser,
  checkUser,
  toppup,
  signClaimMessage,
} from "./web3/getWeb3";
import { useDispatch } from "react-redux";
import { setRefAddress } from "./redux/reducer";
import { useSelector } from "react-redux";
import { contract_adress } from "./web3/config";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useAccount } from "wagmi";
import { cutAfterDecimal, getRoi, getUserStake } from "./web3/api";
import Calculator from "./Calculator";
import { MdError } from "react-icons/md";
import ModelLimit from "./ModelLimit";

const Deposit = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [plan2, setPlan2] = useState(false);

  useEffect(() => {
    if (queryParams.has("WysplanId")) {
      const arbPlanId = queryParams.get("WysplanId");
      if (arbPlanId == 2) {
        setPlan2(true);
      } else {
        setPlan2(false);
      }
    } else {
      console.log("ArbplanId is not available");
    }
  }, [location.search]);

  const [activeTab, setActiveTab] = useState("tab1");
  const [wtod, setWtod] = useState();
  const [res, setRes] = useState(0);
  const [res2, setRes2] = useState(0);
  const [totalClaim, setTotalClaim] = useState(0);
  const [wys, setWys] = useState(0);
  const [wysusdt, setWysusdt] = useState(0);
  const [formInput, setFormInput] = useState("");
  const [formSelect, setFormSelect] = useState();
  const [famt, setFamt] = useState();
  const [isTopup, setIsTopup] = useState(false);
  const [stakepending, setStakePending] = useState(false);
  const { totalStake, dasshBoardDataInfo } = useSelector((state) => state.wallet);
  const { address } = useAccount();
  const [act, setAct] = useState(false);
  const [modelStatus, setModelStatus] = useState(false);

  useEffect(() => {
    if (plan2 == true) {
      setFormSelect(36);
    } else {
      setFormSelect(24);
    }
  }, [plan2]);

  const handleInputChange = (e) => {
    setFormInput(e.target.value);
  };

  const handleSelectChange = (e) => {
    setFormSelect(e.target.value);
  };

  const dispatch = useDispatch();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const { refAddress } = useSelector((state) => state.wallet);
  const wallet = useSelector((state) => state.wallet.walletID);

  //claimwys
  const [count, setCount] = useState(0);

  const claimAmt = async () => {
    try {
      if(res <= 0) {
        toast.error("Withdraw balance not available!!")
        return 
      }
      if(dasshBoardDataInfo?.totalReward === dasshBoardDataInfo?.totalLimit){
        setModelStatus(true)
        return
      }
      const sign = await signClaimMessage(address);
      if (sign) {
        const res = await axios.post(localhost_api + "claim-roi", {
          user: address,
          duration: 24,
          plan: 1,
          amount: 0,
          signature: sign,
          message: address,
        });
        console.log(res.data, res, "res from claim");
        if (res.data.status == 200) {
          toast.success(res.data.message);
        } else {
          toast.error(res.data?.message);
        }
      } else {
        toast.error("Signature failed!");
      }
    } catch (error) {
      toast.error("Error occurred while claiming!", {
        autoClose: 1000,
      });
      console.log(error);
    }
  };

  const claim2 = async () => {
    try {
      if(res2 <= 0) {
        toast.error("Withdraw balance not available!!")
        return 
      }
      if(dasshBoardDataInfo?.totalReward === dasshBoardDataInfo?.totalLimit){
        setModelStatus(true)
        return
      }
      const sign = await signClaimMessage(address);
      if (sign) {
        const res = await axios.post(localhost_api + "claim-roi", {
          user: address,
          duration: 36,
          plan: 1,
          amount: 0,
          signature: sign,
          message: address,
        });
        if (res.data?.status == 200) {
          toast.success(res.data.message);
        } else {
          toast.error(res.data?.message);
        }
      } else {
        toast.error("Signature not valid!");
      }
    } catch (error) {
      toast.error("Error occurred while claiming!", {
        autoClose: 1000,
      });
    }
  };

  useEffect(() => {
    wysPrice().then((res) => {
      var amt = Number(res) / 1e18;
      setWys(amt);
    });
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fullAmt = formInput;
        setFamt(fullAmt);
        const wysTousdt = await getWYSToUSDAmt(famt * 1e18);
        let usAmt = Number(wysTousdt) / 1e18;
        setWysusdt(usAmt * 1e18);
      } catch (error) {}
    };

    fetchData(); // Call the async function inside useEffect
  });
  const [userWysTokenBalance, setUserWysTokenBalance] = useState(0);

  useEffect(() => {
    getBalanceUser(wallet, Wys_address)
      .then((res) => {
        const balance = parseFloat(res.value);

        if (!isNaN(balance)) {
          const truncatedNumber = parseFloat(cutAfterDecimal(balance/1e18,5));
          setUserWysTokenBalance(truncatedNumber);
        } else {
          console.error("Invalid balance value:", res.value);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  });

  async function wysTokenPurchase() {
    try {
      setStakePending(true);
      const registerUSer = await checkUser(wallet);
      const apiregister = await axios.post(localhost_api + "isUserExist", {
        address: wallet,
      });
      console.log(apiregister, apiregister.data?.exist, "res from apiregister");
      if (registerUSer && apiregister.data?.exist) {
        if (formInput > 0) {
          try {
            const getWysToUsdt = await getWYSToUSDAmt(formInput * 1e18);
            const getBalance = await getBalanceUser(wallet, Wys_address);
            const allowanceWYS = await allowance(wallet, contract_adress);
            const allowanceAmt = Number(allowanceWYS) / 1e18;

            console.log(Number(getBalance.value) / 1e18);
            if (Number(getBalance.value) / 1e18 >= formInput) {
              if (allowanceWYS >= formInput * 1e18) {
                console.log(
                  getWysToUsdt,
                  refAddress,
                  formSelect,
                  "data of stajke wys"
                );
                const res = stakeWYS(formInput * 1e18, refAddress, formSelect);
                toast.promise(res, {
                  loading: "farming is pending",
                  success: "Farming Successful 👌",
                  error: "Error In Farming 🤯",
                });

                setTimeout(() => {
                  window.location.reload();
                }, 10000);
              } else {
                const amt_Approve = amtApprove(
                  contract_adress,
                  formInput * 1e18
                );
                toast
                  .promise(amt_Approve, {
                    loading: "Approving the WYS Token",
                    success: "Approving Successful 👌",
                    error: "Approving Failed 🤯",
                  })
                  .then(() => {
                    console.log(
                      getWysToUsdt,
                      refAddress,
                      formSelect,
                      "data of stajke wys"
                    );
                    const res = stakeWYS(
                      formInput * 1e18,
                      refAddress,
                      formSelect
                    );
                    console.log(res, "asasass");
                    toast.promise(res, {
                      loading: "Farming is Pending",
                      success: "Farming Successful 👌",
                      error: "Farming Failed 🤯",
                    });
                  })
                  .catch((err) => {
                    console.log(err);
                  });

                console.log(
                  amt_Approve,
                  ":::::::::::::aaaaaaaaaaaaaaaaaaaaaaaa"
                );
              }
            } else {
              toast.error(
                "User has insufficient balance to perform the transaction."
              );
            }
          } catch (error) {
            toast.error("Error While Farming");
          }
        } else {
          toast.error("Invalid Amount");
        }
      } else {
        toast.error("Plese Signup to Stake");
        setTimeout(() => {
          window.location.href = "/signup";
        }, 1000);
      }
      setStakePending(false);
    } catch (err) {
      setStakePending(false);
      console.log(err);
    }
    setStakePending(false);
  }

  async function TopupId() {
    try {
      setStakePending(true);
      const registerUSer = await checkUser(wallet);
      const apiregister = await axios.post(localhost_api + "isUserExist", {
        address: wallet,
      });
      console.log(apiregister, apiregister.data?.exist, "res from apiregister");
      if (registerUSer && apiregister.data?.exist) {
        if (formInput > 0) {
          try {
            const getWysToUsdt = await getWYSToUSDAmt(formInput * 1e18);
            const getBalance = await getBalanceUser(wallet, Wys_address);
            const allowanceWYS = await allowance(wallet, contract_adress);
            const allowanceAmt = Number(allowanceWYS) / 1e18;

            console.log(Number(getBalance.value) / 1e18);
            if (Number(getBalance.value) / 1e18 >= formInput) {
              if (allowanceWYS >= formInput * 1e18) {
                console.log(
                  getWysToUsdt,
                  refAddress,
                  formSelect,
                  "data of stajke wys"
                );
                const res = toppup(formInput * 1e18);
                toast.promise(res, {
                  loading: "Topup is pending",
                  success: "Topup Successful 👌",
                  error: "Error In Topup 🤯",
                });
              } else {
                const amt_Approve = amtApprove(
                  contract_adress,
                  formInput * 1e18
                );
                toast
                  .promise(amt_Approve, {
                    loading: "Approving the WYS Token",
                    success: "Approving Successful 👌",
                    error: "Approving Failed 🤯",
                  })
                  .then(() => {
                    console.log(
                      getWysToUsdt,
                      refAddress,
                      formSelect,
                      "data of stajke wys"
                    );
                    const res = toppup(formInput * 1e18);
                    console.log(res, "asasass");
                    toast.promise(res, {
                      loading: "Topup is Pending",
                      success: "Topup Successful 👌",
                      error: "Topup Failed 🤯",
                    });
                  })
                  .catch((err) => {
                    console.log(err);
                  });

                console.log(
                  amt_Approve,
                  ":::::::::::::aaaaaaaaaaaaaaaaaaaaaaaa"
                );
              }
            } else {
              toast.error(
                "User has insufficient balance to perform the transaction."
              );
            }
          } catch (error) {
            toast.error("Error While Topup");
          }
        } else {
          toast.error("Invalid Amount");
        }
      } else {
        toast.error("Plese Signup to Topup");
        setTimeout(() => {
          window.location.href = "/signup";
        }, 1000);
      }
      setStakePending(false);
    } catch (err) {
      setStakePending(false);
      console.log(err);
    }
  }

  const [ref, setRef] = useState(false);
  const [to, setTo] = useState("");

  useEffect(() => {
    const currentURL = window.location.href;
    const splited = currentURL.split("?");
    if (splited.length > 1) {
      console.log("Call if");
      const format = splited[1].split("=");
      const params = new URLSearchParams(new URL(currentURL).search);
      if (format[0] == "refID") {
        const refIDValue1 = format[1].split("&");
        const refIDValue = refIDValue1[0];
        setRef(true);
        setTo(refIDValue);
      }
    }
    // console.log(base_url,"basesese")
  });

  const [wystvl, setWystvl] = useState(0);
  const [wystvl1, setWystvl1] = useState(0);

  const { walletID, allStakes } = useSelector((state) => state.wallet);

  useEffect(() => {
    if (address != "") {
      const tvl = async () => {
        try {
          const response = await axios.get(localhost_api + "total-staked", {
            params: {
              user: address,
            },
          });
          if (response) {
            response.data.data.forEach((item) => {
              const { _id, totalStakedAmt } = item;
              const { planId, duration } = _id;

              if (planId == "1" && duration == 24) {
                setWystvl(Number(totalStakedAmt) / 1e18);
              } else if (planId == "1" && duration == 36) {
                setWystvl1(Number(totalStakedAmt) / 1e18);
              }
            });
          }

          const freeIdres = await axios.post(localhost_api + "isfreeId", {
            user: wallet,
          });
          if (freeIdres) {
            if (freeIdres?.data?.status == 200) {
              setIsTopup(freeIdres?.data?.topup);
            }
          }
          // console.log(freeIdres,"res from free id");
        } catch (error) {
          console.error("Error fetching total-staked:", error);
        }
      };
      tvl();
    }
  }, [address]);

  //roi
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    getRoi(address, 24, 1)
      .then((res) => {
        console.log(res, "red From getRoi api1");
        if (res?.data?.status == 200) {
          setRes(res?.data?.roi);
          console.log();
        }
      })
      .catch((err) => {
        console.log(err);
      });
    getRoi(address, 36, 1).then((res) => {
      console.log(res, "red From getRoi api");
      if (res?.data?.status == 200) {
        setRes2(res?.data?.roi);
      }
    });
  }, [address, refresh]);

  return (
    <>
      <Header />
      {/* <ToastContainer position="bottom-right" /> */}
      <Toaster />
      <div className="row col-lg-12" id="w1">
        <div
          className="row col-lg-11"
          style={{
            margin: "auto",
            borderRadius: "21px",
            background: "var(--Gray-900, #171717)",
            height: "auto",
            marginBottom: "20px",
          }}
        >
          <div
            className="col-lg-8"
            style={{ marginTop: "10px", height: "90px" }}
          >
            <Link to={to != "" ? `/app?refID=${to}` : "/app"}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 32 32"
                fill="none"
              >
                <path
                  d="M28 15.9999C28 16.2652 27.8946 16.5195 27.7071 16.7071C27.5196 16.8946 27.2652 16.9999 27 16.9999H7.41375L14.7075 24.2924C14.8004 24.3854 14.8741 24.4957 14.9244 24.6171C14.9747 24.7384 15.0006 24.8686 15.0006 24.9999C15.0006 25.1313 14.9747 25.2614 14.9244 25.3828C14.8741 25.5042 14.8004 25.6145 14.7075 25.7074C14.6146 25.8004 14.5043 25.8741 14.3829 25.9243C14.2615 25.9746 14.1314 26.0005 14 26.0005C13.8686 26.0005 13.7385 25.9746 13.6171 25.9243C13.4957 25.8741 13.3854 25.8004 13.2925 25.7074L4.2925 16.7074C4.19952 16.6146 4.12577 16.5043 4.07544 16.3829C4.02512 16.2615 3.99921 16.1314 3.99921 15.9999C3.99921 15.8685 4.02512 15.7384 4.07544 15.617C4.12577 15.4956 4.19952 15.3853 4.2925 15.2924L13.2925 6.29245C13.4801 6.1048 13.7346 5.99939 14 5.99939C14.2654 5.99939 14.5199 6.1048 14.7075 6.29245C14.8951 6.48009 15.0006 6.73458 15.0006 6.99995C15.0006 7.26531 14.8951 7.5198 14.7075 7.70745L7.41375 14.9999H27C27.2652 14.9999 27.5196 15.1053 27.7071 15.2928C27.8946 15.4804 28 15.7347 28 15.9999Z"
                  fill="white"
                />
              </svg>
            </Link>

            <img
              src="image/wy.png"
              alt=""
              height={35}
              width={35}
              style={{ marginLeft: "5px" }}
            />

            <ul
              style={{
                listStyleType: "none",
                display: "inline-block",
                position: "relative",
                top: "5px",
                left: "-25px",
              }}
            >
              <li
                style={{
                  color: "#FFF",
                  fontFamily: "Satoshi",
                  fontSize: "26px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "normal",
                }}
              >
                WYS
              </li>
            </ul>
          </div>

          <div
            className="row col-lg-7"
            style={{ margin: "0px 20px 10px" }}
            id="c"
          >
            <div
              className="row col-lg-12"
              style={{
                margin: "0px auto",
                borderRadius: "12px",
                height: "auto",
                border: "1px solid",
              }}
            >
              <div className="apy">
                <p>APY</p>
                <h6>
                  {plan2 ? `${(4.55 * 12).toFixed(2)}%` : `${3.75 * 12}%`}
                </h6>
              </div>
              <div className="apy">
                <p>Daily</p>
                <h6>
                  {plan2
                    ? `${((4.55 * 12) / 365).toFixed(2)}% `
                    : `${((3.75 * 12) / 365).toFixed(2)}% `}
                </h6>
              </div>
              <div className="apy">
                <p>TVL</p>
                <h6>{plan2 ? wystvl1.toFixed(1) : wystvl.toFixed(1)} WYS</h6>
              </div>
              <div className="apy">
                <p>My TVL</p>
                <h6>
                  {allStakes
                    ? allStakes?.length > 0
                      ? allStakes.find(
                          (it) =>
                            it.planId == 1 && it.duration == (plan2 ? 36 : 24)
                        )?.ttlAmt > 0
                        ? (
                            allStakes.find(
                              (it) =>
                                it.planId == 1 &&
                                it.duration == (plan2 ? 36 : 24)
                            )?.ttlAmt / 1e18
                          ).toFixed(3)
                        : 0
                      : 0
                    : 0}
                  <span>WYS</span>
                </h6>
              </div>
            </div>
            <div
              className="col-lg-12"
              style={{
                height: "fit-content",
                margin: "20px auto ",
                border: "1px solid",
                borderRadius: "12px",
              }}
            >
              <Chartt />
            </div>
            <div
              className="col-lg-12"
              style={{
                border: "1px solid",
                borderRadius: "12px",
                height: "fit-content",
                margin: "20px 0px ",
              }}
            >
              <table
                className="col-lg-12"
                style={{ margin: " 20px auto" }}
                id="brk"
              >
                <thead>
                  <tr style={{ display: "inline-block" }}>
                    <th
                      style={{
                        color: "#7D7B7B",
                        fontWeight: "500",
                        fontSize: "18px",
                      }}
                    >
                      APY Breakdown
                    </th>
                  </tr>
                  <tr>
                    <th className="tj">WYS Rewards</th>
                    <th className="td">
                      <span className="td">{plan2 ? "11.11%" : "8.33%"}</span>
                    </th>
                  </tr>
                  <tr>
                    <th className="tj">Breakdown Details:</th>
                    <th className="td"></th>
                  </tr>
                  <tr>
                    <th className="tj">APY</th>
                    <th className="td">
                      <span className="td"> {plan2 ? "4.55%" : "3.75%"} </span>
                    </th>
                  </tr>
                  <tr>
                    <th className="tj">Reward </th>
                    <th className="td">
                      <span className="td">{plan2 ? "6.56%" : "4.58%"}</span>
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            <div
              className="col-lg-12"
              style={{
                border: "1px solid",
                borderRadius: "12px",
                height: "fit-content",
              }}
            >
              <div className="row col-lg-12">
                <p style={{ color: "#7D7B7B", marginTop: "5px" }}>
                  Farm Details
                </p>
                {plan2 ? (
                  <p style={{ color: "white" }}>
                    The WYS farming pool offers a maximum of 2,000,000 WYS
                    tokens to earn yields up to 11.11% APY with a 4x multiplier.
                    Rewards are paid out in WYS over 36 months. Users deposit
                    WYS and harvest WYS tokens as farming rewards.
                  </p>
                ) : (
                  <p style={{ color: "white" }}>
                    The WYS farming pool offers a maximum of 2,000,000 WYS
                    tokens to earn yields up to 8.33% APY with a 2x multiplier.
                    Rewards are paid out in WYS over 24 months. Users deposit
                    WYS and harvest WYS tokens as farming rewards.
                  </p>
                )}

                {/* <div className="row col-lg-12" id="vault">
                  <Link to="javascripy:void(0)">Vault Address</Link>
                  <Link to="javascripy:void(0)">Strategy Address</Link>
                  <Link to="javascripy:void(0)">Pool Address</Link>
                </div> */}
              </div>
            </div>
          </div>

          <div
            className="row col-lg-4 r1"
            style={{
              borderRadius: "12px",
              border: "1px solid var(--Gray-700, #313131)",
              marginBottom: "20px",
              height: "fit-content",
            }}
          >
            <div className="row tab-header col-lg-7">
              <button
                onClick={() => handleTabClick("tab1")}
                className={activeTab === "tab1" ? "active" : ""}
                data-toggle="modal"
                data-target="#exampleModalDeposit"
              >
                Deposit
              </button>
              <button
                onClick={() => {
                  handleTabClick("tab2");
                  setRefresh(!refresh);
                }}
                className={activeTab === "tab2" ? "active" : ""}
              >
                Withdraw
              </button>
            </div>
            <div className="col-lg-3 cp" style={{ textAlign: "end" }}>
              <i
                class="fa-solid fa-calculator calc"
                style={{
                  color: "white",
                  position: "relative",
                  fontSize: "20px",
                  top: "13px",
                  right: "-15px",
                  cursor: "pointer",
                  // zIndex:999
                }}
                onClick={() => {
                  setAct(true);
                }}
              ></i>
            </div>
            <div className=" row tab-content">
              {activeTab === "tab1" && (
                <div className=" row col-lg-12" style={{ margin: "20px auto" }}>
                  <div
                    className="col-lg-10"
                    style={{
                      margin: "20px auto",
                      position: "relative",
                      borderRadius: "12px",
                      border: "1px solid #313131",
                    }}
                  >
                    <span
                      style={{
                        color: "white",
                        display: "inline-block",
                        margin: "10px",
                      }}
                    >
                      Deposit
                    </span>
                    <select
                      href=""
                      style={{
                        color: "white",
                        position: "absolute",
                        right: "5px",
                        margin: "10px",
                      }}
                      id="taba"
                      value={formSelect}
                      onChange={handleSelectChange}
                    >
                      {plan2 ? (
                        <>
                          <option value="36" selected>
                            36 Month
                          </option>
                          <option value="24">24 Month</option>
                        </>
                      ) : (
                        <>
                          <option value="24" selected>
                            24 Month
                          </option>
                          <option value="36">36 Month</option>
                        </>
                      )}
                    </select>
                    <div id="td">
                      <p id="tabp">
                        <input
                          type="text"
                          placeholder="1000 WYS..."
                          id="tabp"
                          name="inputValue"
                          value={formInput}
                          onChange={handleInputChange}
                          style={{
                            border: "1px solid #313131",
                            outline: "none",
                            borderRadius: "6px",
                            color: formInput ? "#e1d31b" : "#7d7b7b",
                            // width: "100%",
                            // fontSize: formInput ? "20px" : "16px",
                            // lineHeight:'1'
                          }}
                          className="custom-input"
                        />{" "}
                        <button
                          className="btn-gradient"
                          onClick={() => {
                            setFormInput(userWysTokenBalance);
                          }}
                          style={{
                            borderRadius: "0px",
                            border: "1px solid",
                            borderRadius: "3px",
                          }}
                        >
                          Max
                        </button>
                      </p>

                      <h6 style={{ color: "white", paddingLeft: "12px" }}>
                        Referral Address
                      </h6>
                      <p id="tabp">
                        <input
                          type="text"
                          readOnly
                          placeholder="1000 WYS..."
                          id="tabp"
                          name="referral"
                          value={
                            refAddress?.slice(0, 3) +
                            "..." +
                            refAddress?.slice(-5)
                          }
                          // onChange={handleInputChange}
                          style={{
                            border: "1px solid #313131",
                            outline: "none",
                            borderRadius: "6px",
                            color: formInput ? "#e1d31b" : "#7d7b7b",
                          }}
                          className="custom-input"
                        />{" "}
                      </p>
                      <div
                        className="row col-lg-12"
                        style={{
                          margin: "10px auto",
                          borderRadius: "12px",
                          height: "fit-content",
                          border: "1px solid",
                        }}
                      >
                        <div className="apy apy1" style={{ width: "99%" }}>
                          <p style={{ margin: "0px" }}> WYS Token Balance</p>
                          <h6>{cutAfterDecimal(userWysTokenBalance, 3)}</h6>
                        </div>
                      </div>
                      <div
                        className="row col-lg-12"
                        style={{
                          margin: "10px auto",
                          borderRadius: "12px",
                          height: "fit-content",
                          border: "1px solid",
                        }}
                      >
                        <div className="apy" style={{ width: "50%" }}>
                          <p style={{ margin: "0px" }}> Amount</p>
                          <h6>{(wysusdt / 1e18).toFixed(2)} $</h6>
                        </div>
                        <div className="apy" style={{ width: "50%" }}>
                          <p style={{ margin: "0px" }}>
                            {(wysusdt / wys / 1e18).toFixed(2)} WYS
                          </p>
                          <h6>{(wysusdt / 1e18).toFixed(2)} $</h6>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-lg-10"
                    style={{
                      margin: "20px auto",
                      borderRadius: "6px",
                      background:
                        "linear-gradient(90deg, #A2D254 0%, #FFD300 100%)",
                      lineHeight: "2",
                    }}
                  >
                    <a
                      href="javascript:void(0)"
                      style={{
                        color: "black",
                        textAlign: "center",
                        width: "100%",
                        display: "inline-block",
                        fontSize: "25px",
                        fontFamily: "Satoshi",
                      }}
                      onClick={() => {
                        if (isTopup) {
                          if (!stakepending) {
                            TopupId();
                          } else {
                            toast.error(
                              "Please wait for the previous transaction to be completed"
                            );
                          }
                        } else {
                          if (!stakepending) {
                            wysTokenPurchase();
                          } else {
                            toast.error(
                              "Please wait for the previous transaction to be completed"
                            );
                          }
                        }
                      }}
                    >
                      {isTopup ? "Topup" : "Deposit"}
                    </a>
                  </div>
                  <table className="col-lg-10" style={{ margin: "auto" }}>
                    <thead>
                      <tr>
                        <th className="tj">Total Invested</th>
                        <th className="te">
                          {" "}
                          $ {(totalStake * wys).toFixed(1)}
                        </th>
                      </tr>
                      <tr>
                        <th className="tj">Underlying Balance</th>
                        <th className="te">{totalStake.toFixed(2)} WYS</th>
                      </tr>
                      <tr>
                        <th className="tj">Current Price</th>
                        <th className="te">${wys.toFixed(3)}</th>
                      </tr>
                      {/* <tr>
                        <th className="tj">Total Value</th>
                        <th className="te">$15.00</th>
                      </tr> */}
                    </thead>
                  </table>
                </div>
              )}

              {activeTab === "tab2" && (
                <div className=" row col-lg-12" style={{ margin: "20px auto" }}>
                  <table className="col-lg-10" style={{ margin: "auto" }}>
                    <thead>
                      <tr>
                        <th className="tj">Balance Available to Withdraw</th>
                        <th className="te">
                          {" "}
                          {plan2
                            ? cutAfterDecimal(res2, 3)
                            : cutAfterDecimal(res, 3)}
                        </th>
                      </tr>
                    </thead>
                  </table>

                  <div
                    className="col-lg-10"
                    style={{
                      margin: "20px auto",
                      borderRadius: "6px",
                      background:
                        "linear-gradient(90deg, #A2D254 0%, #FFD300 100%)",
                      lineHeight: "2",
                    }}
                  >
                    <a
                      href="javascript:void(0)"
                      style={{
                        color: "black",
                        textAlign: "center",
                        width: "100%",
                        display: "inline-block",
                        fontSize: "25px",
                        fontFamily: "Satoshi",
                      }}
                      className="with"
                      onClick={() => (plan2 ? claim2() : claimAmt())}
                    >
                      Withdraw to wallet
                    </a>
                  </div>
                  <table className="col-lg-10" style={{ margin: "auto" }}>
                    <thead>
                      {/* <tr>
                        <th className="tj">Deposit Fees</th>
                        <th className="te">No platform fee</th>
                      </tr> */}
                      <tr>
                        <th className="tj">Withdrawal Fees</th>
                        <th className="te">Zero Fees</th>
                      </tr>
                    </thead>
                  </table>
                </div>
              )}
            </div>
            <Calculator act={act} setAct={setAct} />
          </div>
        </div>
      </div>
      <ModelLimit setModelStatus={setModelStatus} modelStatus={modelStatus}/>

    </>
  );
};

export default Deposit;
