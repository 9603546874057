import React from 'react'
import { MdError } from 'react-icons/md'

export default function ModelLimit({modelStatus, setModelStatus}) {
  return (
    <>
        <div class={`modal ${modelStatus ? "show d-block" :"d-none"} `} tabindex="-1">
                  <div class="modal-dialog modal-dialog-centered">
                    <div
                      class="modal-content border-dark"
                      style={{
                        background: "#1c1c1c",
                      }}
                    >
                      <div class="modal-header border-0">
                      <h5 class="modal-title" id="exampleModalLongTitle"></h5>

                        <button
                          type="button"
                          class=" text-white"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          id="close-btn"
                          style={{
                            background: "black",
                            border: "none",
                            outline: "none",
                            cursor: "pointer",
                          }}
                          onClick={()=>setModelStatus(false)}
                        >
                          X
                        </button>
                      </div>
                      
                      <div className="pb-4 ps-2 pe-2 text-light d-flex justify-content-center">
                      <div className="mb-4">
                      <div className="d-flex justify-content-center mb-3"><MdError color="yellow" size={70} /></div>
                      <div>Your limit reached. Please top up to continue.</div>
                      </div>
                      
                      </div>
                    </div>
                  </div>
                </div>
    </>
  )
}
