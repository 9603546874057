import React, { useEffect, useState } from "react";
import "./Vertical.css";
import Header from "./Header";
import { useDispatch, useSelector } from "react-redux";
import { setTotalStake } from "./redux/reducer";

import axios from "axios";
import moment from "moment";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { processWysAmount } from "./web3/getWeb3";
import { Link } from "react-router-dom";
import { useAccount } from "wagmi";
import { api_url, localhost_api } from "./web3/config";
import toast, { Toaster } from "react-hot-toast";
import { redirectUser } from "./web3/api";
import { FaUserFriends } from "react-icons/fa";

const User = () => {
  const [selectedTab, setSelectedTab] = useState("tab1"); // State to manage selected tab
  const [show, setShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  // const { address } = useSelector((state) => state.wallet);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage] = useState(10);
  const [directUserData, setDirectUserData] = useState(null);
  const [currentdirectPage, setCurrentdirectPage] = useState(1);
  const [team, setTeam] = useState(null);
  const [currentTeamPage, setCurrentTeamPage] = useState(1);
  const [levelIncome, setLevelIncome] = useState(null);
  const [currentLevelPage, setCurrentLevelPage] = useState(1);
  const [claimIncome, setClaimIncome] = useState(null);
  const [currentClaimPage, setCurrentClaimPage] = useState(1);
  const { address } = useAccount();
  // const address = "0xD1628bB344523E7Fc073D91372BCfe7f14520b57";
  const [pool, setPool] = useState();
  const [dashboard, setDashboard] = useState(null);
  const [maxlimit, setMaxlimit] = useState(0);
  const [avlReward, setAvlReward] = useState(0);
  const [totalRoi, setTotalRoi] = useState(0);
  const [leg, setLeg] = useState();
  const [maxLeg, setMaxLeg] = useState();
  const [minLeg, setMinLeg] = useState();
  const [usertotalRoi, setUsertotalRoi] = useState();
  const [poolDataPage, setPoolDataPage] = useState();
  const [poolIncomeData, setPoolIncomeData] = useState([]);
  const [lapseRewardHist, setLapseRewardHist] = useState();
  const [poolTotalPage, setPoolTotalPAge] = useState();
  const [claimRewardData, setClaimRewardData] = useState();
  const [claimRewardPage, setClaimRewardPage] = useState(1);
  const [claimRewardTotalPage, setClaimRewardTotalPage] = useState();
  const [is70Percent, setIs70Percent] = useState(false);

  const handleTabClick = (tabId) => {
    setSelectedTab(tabId);
  };
  const handleMenu = () => {
    if (show == false) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  const fetchDirectData = async (page) => {
    try {
      const response = await axios.get(localhost_api + "direct-list", {
        params: {
          user: address,
          page: page,
          limit: itemsPerPage,
        },
      });
      if (response.data.data != undefined) {
        setDirectUserData(
          response.data.data.sort((a, b) => b.teamBusiness - a.teamBusiness)
        );
        const totalItems = response.data.totalDataCount;
        const calculatedTotalPages = Math.ceil(totalItems / itemsPerPage);
        setTotalPages(calculatedTotalPages);
      }
    } catch (error) {
      console.log("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDirectPageChange = (event, value) => {
    setCurrentdirectPage(value);
    fetchDirectData(value);
  };

  useEffect(() => {
    fetchDirectData(currentdirectPage); // Fix the function name here
  }, [address, currentdirectPage]);

  const fetchTeamData = async (page) => {
    try {
      const response = await axios.get(localhost_api + "team-list", {
        params: {
          user: address,
          page: page,
          limit: itemsPerPage,
        },
      });
      if (response.data.data != undefined) {
        setTeam(response.data.data);
        // console.log(response.data.data);
        const totalItems = response.data.totalDataCount;

        const calculatedTotalPages = Math.ceil(totalItems / itemsPerPage);
        setTotalPages(calculatedTotalPages);
      }
    } catch (error) {
      // Handle errors here
      console.log("Error fetching data:", error);
    }
  };

  const handleTeamPageChange = (event, value) => {
    setCurrentTeamPage(value);
    fetchTeamData(value);
  };

  useEffect(() => {
    fetchTeamData(currentTeamPage); // Fix the function name here
  }, [address, currentTeamPage]);

  const fetchLevelData = async (page) => {
    try {
      const response = await axios.get(localhost_api + "level-income", {
        params: {
          user: address,
          page: page,
          limit: itemsPerPage,
        },
      });

      if (response.data.data != undefined) {
        setLevelIncome(response.data.data);
        const totalItems = response.data.totalDataCount;

        const calculatedTotalPages = Math.ceil(totalItems / itemsPerPage);
        setTotalPages(calculatedTotalPages);
      }
    } catch (error) {
      // Handle errors here
      console.log("Error fetching data:", error);
    }
  };

  const handleLevelPageChange = (event, value) => {
    setCurrentLevelPage(value);
    fetchLevelData(value);
  };
  useEffect(() => {
    fetchLevelData(currentLevelPage); // Fix the function name here
  }, [address, currentLevelPage]);

  const claimIncomeData = async (page) => {
    try {
      const response = await axios.get(localhost_api + "claim-list", {
        params: {
          user: address,
          page: page,
          limit: itemsPerPage,
        },
      });
      if (response.data.data != undefined) {
        setClaimIncome(response.data.data);
        const totalItems = response.data.totalDataCount;

        const calculatedTotalPages = Math.ceil(totalItems / itemsPerPage);
        setTotalPages(calculatedTotalPages);
      }
    } catch (error) {
      // Handle errors here
      console.log("Error fetching data:", error);
    }
  };
  const claimIncomeRewardData = async (page) => {
    try {
      const response = await axios.get(localhost_api + "reward-claim-list", {
        params: {
          user: address,
          page: page,
          limit: itemsPerPage,
        },
      });
      if (response.data.data != undefined) {
        console.log(response.data.data, "response.data.dataresponse.data.data");
        setClaimRewardData(response.data.data);
        const totalItems = response.data.totalDataCount;

        const calculatedTotalPages = Math.ceil(totalItems / itemsPerPage);
        setClaimRewardTotalPage(calculatedTotalPages);
      }
    } catch (error) {
      // Handle errors here
      console.log("Error fetching data:", error);
    }
  };

  const handleClaimRewardPageChange = (event, value) => {
    setClaimRewardPage(value);
    setClaimRewardPage(value);
  };
  useEffect(() => {
    claimIncomeRewardData(claimRewardPage);
  }, [claimRewardPage, address]);

  const handleClaimPageChange = (event, value) => {
    setCurrentClaimPage(value);
    claimIncomeData(value);
  };
  useEffect(() => {
    claimIncomeData(currentClaimPage); // Fix the function name here
  }, [address, currentClaimPage]);

  const dashboardData = async () => {
    try {
      const response = await axios.get(localhost_api + "dashboard", {
        params: {
          user: address,
        },
      });

      if (response) {
        console.log(
          dashboard?.totalReward,
          dashboard?.availabelReward,
          dashboard?.totalLimit,
          "sss"
        );
        setIs70Percent(
          ((Number(dashboard?.totalReward) +
            Number(dashboard?.availabelReward)) *
            100) /
            Number(dashboard?.totalLimit)
        );
        setDashboard(response.data.data);
        setMaxlimit(
          response.data.data?.totalLimit
            ? response.data.data?.totalLimit / 1e18
            : 0
        );
        setAvlReward(
          response.data.data?.totalReward
            ? response.data.data?.totalReward / 1e18
            : 0
        );
        const totalReward = response.data.data?.totalReward / 1e18;
        const totalLimit = response.data.data?.totalLimit / 1e18;

        if (totalReward.toFixed(2) == totalLimit.toFixed(2) && totalLimit > 0) {
          // console.log("suraj if");
          setIsOpen(true);
        } else {
          // console.log("suraj Else");
          setIsOpen(false);
        }
        // console.log("TotalReward and TotalLimit", totalLimit, totalReward);
        // console.log(response.data.data?.totalLimit / 1e18, "maxlimuit");
      }
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  useEffect(() => {
    if (address) {
      dashboardData();
    }
  }, [address]);

  useEffect(() => {
    const fetchlegdata = async () => {
      try {
        const response = await axios.get(localhost_api + "direct-list", {
          params: {
            user: address,
            // page: page,
            limit: dashboard?.directCount,
          },
        });
        if (
          response.data.data != undefined &&
          Array.isArray(response.data.data)
        ) {
          setLeg(response.data.data);
          const totalItems = response.data.totalDataCount;

          if (response.data.data.length > 0) {
            const teamBusinessValues = response.data.data.map(
              (item) => item.teamBusiness
            );
            const maxTeamBusinessValue = Math.max(...teamBusinessValues);
            const sumOfRemainingTeamBusiness = teamBusinessValues
              .filter((value) => value !== maxTeamBusinessValue)
              .reduce((sum, value) => sum + value, 0);
            if (dashboard?.rank >= 0) {
              var currentRank = dashboard?.rank;
            }

            const totalMax_leg = maxTeamBusinessValue / 1e18;
            const seventypercent = ((maxTeamBusinessValue / 1e18) * 70) / 100;
            const thirtypercent =
              ((sumOfRemainingTeamBusiness / 1e18) * 30) / 100;
            // console.log(currentRank, "We are checking Rank");
            var maxlegcanbe = 350;
            var minlegcanbe = 150;
            if (currentRank == 0) {
              maxlegcanbe = 350 * 1;
              minlegcanbe = 150 * 1;
            } else if (currentRank == 1) {
              maxlegcanbe = (1000 * 70) / 100;
              minlegcanbe = (1000 * 30) / 100;
            } else if (currentRank == 2) {
              maxlegcanbe = (2500 * 70) / 100;
              minlegcanbe = (2500 * 30) / 100;
            } else if (currentRank == 3) {
              maxlegcanbe = (5000 * 70) / 100;
              minlegcanbe = (5000 * 30) / 100;
            } else if (currentRank == 4) {
              maxlegcanbe = (10000 * 70) / 100;
              minlegcanbe = (10000 * 30) / 100;
            } else if (currentRank == 5) {
              maxlegcanbe = (25000 * 70) / 100;
              minlegcanbe = (25000 * 30) / 100;
            } else if (currentRank == 6) {
              maxlegcanbe = (50000 * 70) / 100;
              minlegcanbe = (50000 * 30) / 100;
            } else if (currentRank == 7) {
              maxlegcanbe = (100000 * 70) / 100;
              minlegcanbe = (100000 * 30) / 100;
            } else if (currentRank == 8) {
              maxlegcanbe = (200000 * 70) / 100;
              minlegcanbe = (200000 * 30) / 100;
            } else if (currentRank == 9) {
              maxlegcanbe = (200000 * 70) / 100;
              minlegcanbe = (200000 * 30) / 100;
            } else {
              maxlegcanbe = (200000 * 70) / 100;
              minlegcanbe = (200000 * 30) / 100;
            }

            if (maxTeamBusinessValue / 1e18 > maxlegcanbe) {
              setMaxLeg(maxlegcanbe);
            } else {
              setMaxLeg(maxTeamBusinessValue / 1e18);
            }
            if (sumOfRemainingTeamBusiness / 1e18 > minlegcanbe) {
              setMinLeg(minlegcanbe);
            } else {
              setMinLeg(sumOfRemainingTeamBusiness / 1e18);
            }
          } else {
            console.log("Array is empty");
          }
        } else {
          console.log("Data is not an array or is undefined");
        }
      } catch (error) {
        console.log("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchlegdata();
  }, [dashboard?.directCount]);

  if (dashboard?.directCount) {
    var level;
    var amount = maxLeg + minLeg;
    var minout = 0;
    var maxout = 0;

    if (amount >= 0 && amount < 500) {
      level = "Bronze";
      minout = (500 * 30) / 100;
      maxout = (500 * 70) / 100;
    } else if (amount >= 500 && amount < 1000) {
      level = "Silver";
      // Calculate minout and maxout for Silver level
      minout = (1000 * 30) / 100;
      maxout = (1000 * 70) / 100;
    } else if (amount >= 1000 && amount < 2500) {
      level = "Gold";
      // Calculate minout and maxout for Gold level
      minout = (2500 * 30) / 100;
      maxout = (2500 * 70) / 100;
    } else if (amount >= 2500 && amount < 5000) {
      level = "Platinum";
      // Calculate minout and maxout for Platinum level
      minout = (5000 * 30) / 100;
      maxout = (5000 * 70) / 100;
    } else if (amount >= 5000 && amount < 10000) {
      level = "Beryl";
      // Calculate minout and maxout for Beryl level
      minout = (10000 * 30) / 100;
      maxout = (10000 * 70) / 100;
    } else if (amount >= 10000 && amount < 25000) {
      level = "Sapphire";
      // Calculate minout and maxout for Sapphire level
      minout = (25000 * 30) / 100;
      maxout = (25000 * 70) / 100;
    } else if (amount >= 25000 && amount < 50000) {
      level = "Ruby";
      // Calculate minout and maxout for Ruby level
      minout = (50000 * 30) / 100;
      maxout = (50000 * 70) / 100;
    } else if (amount >= 50000 && amount < 100000) {
      level = "Emerald";
      // Calculate minout and maxout for Emerald level
      minout = (100000 * 30) / 100;
      maxout = (100000 * 70) / 100;
    } else if (amount >= 100000 && amount < 200000) {
      level = "Diamond";
      // Calculate minout and maxout for Diamond level
      minout = (200000 * 30) / 100;
      maxout = (200000 * 70) / 100;
    } else if (amount >= 200000) {
      level = "Diamond";
      // Calculate minout and maxout for Diamond level (you might adjust this based on your logic)
      minout = (amount * 30) / 100;
      maxout = (amount * 70) / 100;
    } else {
      level = "Unknown"; // Default level if none of the conditions are met
      minout = 0;
      maxout = 0;
    }
  } else {
    // console.log("no leg bonus");
  }

  const getTotalRoi = async () => {
    try {
      if (address != "") {
        const res = await axios.get(localhost_api + "get-all-roi", {
          params: {
            user: address,
          },
        });
        // console.log(res, res.data.status, "res from get all roi");
        if (res.data.status == 200) {
          setUsertotalRoi(res.data.roi);
          setTotalRoi(res?.data?.totalroi ? res?.data?.totalroi : 0);
          // console.log(res.data.roi, res.data.totalroi, "res from get all roi");
        }
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getTotalRoi();
  }, [address]);

  const getPoolData = async (page = 1) => {
    try {
      const res = await axios.get(localhost_api + "pool-income-list", {
        params: {
          user: address,
          page: page,
          limit: 10,
        },
      });
      console.log(res.data, ":::::::1235");
      console.log(res.data.totalPages, ":123123131");
      setPoolIncomeData(res.data.data);
      setPoolTotalPAge(res.data.totalPages);
    } catch (error) {
      console.log(error);
    }
  };
  const geLapseReward = async (page = 1) => {
    try {
      const res = await axios.get(
        localhost_api + "transferLapseReward-history",
        {
          params: {
            user: address,
            page: page,
            limit: 10,
          },
        }
      );
      console.log(res.data.data, ":::::::123585985844");
      console.log(res?.data?.totalPages, ":123123131");
      setLapseRewardHist(res.data.data);
      // setPoolTotalPAge(res.data.totalPages);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePoolPageChange = (event, value) => {
    setPoolDataPage(value);
    console.log(value, ":::::::1235");
    // claimIncomeData(value);
  };

  useEffect(() => {
    if (address && selectedTab == "tab7") {
      getPoolData(poolDataPage);
    }
  }, [address, poolDataPage, selectedTab]);

  const transferLapse = async () => {
    try {
      if (!address) {
        return toast.error("Please connect wallet");
      }
      const res = await axios.post(api_url + "transferLapseReward", {
        user: address,
      });
      if (res?.data?.status == 200) {
        toast.success("Successfully transfered ");
        setTimeout(async () => {
          await dashboardData();
        }, 2000);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  return (
    <>
      <Header />
      <Toaster />
      <div className={`${address ? "" : "blur"}`}>
        <div
          className="row col-lg-12 border-primary"
          style={{ margin: "0px", padding: "0px" }}
        >
          <div
            className="mob-nav"
            style={{ display: "none" }}
            onClick={handleMenu}
          >
            <i
              className={` ${show ? "" : "fa fa-bars"}`}
              style={{
                color: "white",
                marginTop: "10px",
                fontSize: "30px",
              }}
            ></i>
          </div>
          <div
            className={`verticalNav ${show ? "show1" : ""}`}
            style={{ width: "150px", position: "relative" }}
          >
            <p
              onClick={handleMenu}
              style={{
                position: "absolute",
                color: "white",
                fontSize: "20px",
                right: "20px",
                display: "none",
              }}
              className="hide"
            >
              <i className="fa fa-close"></i>
            </p>

            <a
              href="javascript:void(0)"
              onClick={() => {
                handleTabClick("tab1");
                dashboardData();
              }}
              className={selectedTab === "tab1" ? "active-tab" : ""}
              style={{ marginTop: "20px" }}
            >
              <p>
                <i class="fa fa-user" aria-hidden="true"></i>
              </p>
              User
            </a>

            <a
              href="javascript:void(0)"
              onClick={() => {
                handleTabClick("tab3");
                fetchDirectData();
              }}
              className={selectedTab === "tab3" ? "active-tab" : ""}
            >
              <p>
                <i class="fa-solid fa-dollar-sign"></i>
              </p>
              Direct
            </a>
            <a
              href="javascript:void(0)"
              onClick={() => {
                handleTabClick("tab4");
                fetchTeamData();
              }}
              className={selectedTab === "tab4" ? "active-tab" : ""}
            >
              <p>
                <i class="fa-solid fa-user-group"></i>
              </p>
              Referral
            </a>
            <a
              href="javascript:void(0)"
              onClick={() => {
                handleTabClick("tab5");
                fetchLevelData();
              }}
              className={selectedTab === "tab5" ? "active-tab" : ""}
            >
              <p>
                <i class="fa-solid fa-credit-card"></i>
              </p>
              Referral Reward
            </a>
            {/* <a
              href="javascript:void(0)"
              onClick={() => {
                handleTabClick("tab6");
                claimIncomeData();
              }}
              className={selectedTab === "tab6" ? "active-tab" : ""}
            >
              <p>
                <i class="fa-solid fa-credit-card"></i>
              </p>
              Claimed APY
            </a> */}

            <a
              href="javascript:void(0)"
              onClick={() => {
                handleTabClick("tab9");
                claimIncomeRewardData();
              }}
              className={selectedTab === "tab9" ? "active-tab" : ""}
            >
              <p>
                <i class="fa-solid fa-credit-card"></i>
              </p>
              Claimed Reward
            </a>

            <a
              href="javascript:void(0)"
              onClick={() => {
                handleTabClick("tab7");
                getPoolData(poolDataPage);
              }}
              className={selectedTab === "tab7" ? "active-tab" : ""}
            >
              <p>
                <i class="fa-solid fa-hand-holding-dollar"></i>
              </p>
              Pool Reward
            </a>

            <a
              href="javascript:void(0)"
              onClick={() => {
                handleTabClick("tab8");
                geLapseReward(poolDataPage);
              }}
              className={selectedTab === "tab8" ? "active-tab" : ""}
            >
              <p>
                <i class="fa-solid fa-hand-holding-dollar"></i>
              </p>
              Residual Reward
            </a>

            <a
              href="javascript:void(0)"
              onClick={() => {
                redirectUser(address);
              }}
              // className={selectedTab === "tab6" ? "active-tab" : ""}
            >
              <p style={{ marginTop: "30px" }}>
                <FaUserFriends style={{ fontSize: "35px" }} />
              </p>
              DMS
            </a>
            <Link
              to={`/app`}
              onClick={() => handleTabClick("tab1")}
              // className={selectedTab === "tab1" ? "active-tab" : ""}
            >
              <p>
                <i class="fa-solid fa-circle-dollar-to-slot"></i>
              </p>
              Farm Pools
            </Link>
          </div>

          <div
            className={`row con ${show ? "" : "con1"}`}
            style={{
              width: `calc(100% - 150px)`,
              color: "white",
            }}
          >
            {selectedTab === "tab1" && (
              <div>
                {" "}
                <div
                  className="row col-lg-11 userCard"
                  style={{ margin: "auto" }}
                >
                  {is70Percent >= 70 && (
                    <div
                      class="alert alert-warning alert-dismissible fade show col-lg-11 m-auto alert-user justify-content-between d-flex align-items-center"
                      role="alert"
                    >
                      <span>
                        Hello {dashboard?.userId || "User"}, your total limit is{" "}
                        {dashboard?.totalLimit
                          ? (dashboard.totalLimit / 1e18).toFixed(2)
                          : "0.00"}{" "}
                        WYS, and you have earned{" "}
                        {is70Percent > 100 ? 100 : is70Percent?.toFixed(2)}
                        %.
                        {is70Percent > 100 &&
                          "Please Topup to claim your availaible or residual reward ."}
                      </span>

                      <i
                        class="fa-solid fa-xmark"
                        type="button"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                        style={{
                          position: "absolute",
                          right: "11px",
                          top: "6px",
                        }}
                      ></i>
                    </div>
                  )}
                  <div
                    className=" row col-lg-3"
                    style={{ margin: "10px auto" }}
                  >
                    <div
                      style={{
                        width: "fit-content",
                        display: "inline-block",
                      }}
                    >
                      <span className="icons">
                        <i class="fa-solid fa-user"></i>
                      </span>
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        width: "160px",
                        fontSize: "18px",
                        paddingTop: "5px",
                        paddingLeft: "15px",
                      }}
                    >
                      <p>User Id :</p>
                      {dashboard && <p>{dashboard.userId ?? "0"}</p>}
                    </div>
                  </div>
                  <div className="row col-lg-3" style={{ margin: "10px auto" }}>
                    <div
                      style={{
                        width: "fit-content",
                        display: "inline-block",
                      }}
                    >
                      <span className="icons">
                        <i
                          class="fa fa-user-plus"
                          aria-hidden="true"
                          style={{ fontSize: "40px" }}
                        ></i>
                      </span>
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        width: "150px",
                        fontSize: "18px",
                        paddingTop: "5px",
                      }}
                    >
                      <p>Referral Id :</p>
                      <p>{dashboard?.referrerId}</p>
                    </div>
                  </div>
                  <div className="row col-lg-3" style={{ margin: "10px auto" }}>
                    <div
                      style={{
                        width: "fit-content",
                        display: "inline-block",
                      }}
                    >
                      <span className="icons">
                        <i
                          class="fa fa-user-plus"
                          aria-hidden="true"
                          style={{ fontSize: "40px" }}
                        ></i>
                      </span>
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        width: "150px",
                        fontSize: "18px",
                        paddingTop: "5px",
                      }}
                    >
                      <p>Referrer :</p>
                      {dashboard && (
                        <p>
                          {dashboard?.referrer.slice(0, 3)}...
                          {dashboard?.referrer.slice(-6)}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className="row col-lg-11 userCard"
                  style={{ margin: "auto" }}
                >
                  <div className="row col-lg-3" style={{ margin: "10px auto" }}>
                    <div
                      style={{
                        width: "fit-content",
                        display: "inline-block",
                      }}
                    >
                      <span className="icons">
                        <i class="fa-solid fa-wallet" aria-hidden="true"></i>
                      </span>
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        width: `calc(100% - 90px)`,
                        fontSize: "18px",
                        paddingTop: "5px",
                      }}
                    >
                      <p>Total Farm :</p>
                      <p>
                        {dashboard && (dashboard?.wysStaked / 1e18).toFixed(2)}{" "}
                      </p>
                    </div>
                  </div>

                  <div className="row col-lg-3" style={{ margin: "10px auto" }}>
                    <div
                      style={{
                        width: "fit-content",
                        display: "inline-block",
                      }}
                    >
                      <span className="icons">
                        <i class="fa-solid fa-dollar"></i>
                      </span>
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        width: "calc(100% - 80px)",
                        fontSize: "18px",
                        paddingTop: "5px",
                      }}
                    >
                      <p>APY</p>
                      <p style={{ position: "relative" }}>
                        {totalRoi?.toFixed(6)}

                        <span
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          style={{ position: "absolute", right: "0" }}
                        >
                          <i
                            class="fa fa-eye "
                            aria-hidden="true"
                            style={{ cursor: "pointer" }}
                          ></i>
                        </span>
                      </p>
                    </div>
                  </div>

                  <div className="row col-lg-3" style={{ margin: "10px auto" }}>
                    <div
                      style={{
                        width: "fit-content",
                        display: "inline-block",
                      }}
                    >
                      <span className="icons">
                        <i class="fa-solid fa-briefcase"></i>
                      </span>
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        width: `calc(100% - 90px)`,
                        fontSize: "20px",
                        paddingTop: "5px",
                      }}
                    >
                      <p>Direct Reward :</p>
                      <p>
                        {dashboard &&
                          (dashboard?.directBonus / 1e18).toFixed(2)}{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="row col-lg-11 userCard"
                  style={{ margin: "auto" }}
                >
                  <div className="row col-lg-3" style={{ margin: "10px auto" }}>
                    <div
                      style={{
                        width: "fit-content",
                        display: "inline-block",
                      }}
                    >
                      <span className="icons">
                        <i
                          class="fa-solid fa-user-group"
                          style={{ fontSize: "40px" }}
                        ></i>
                      </span>
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        width: `calc(100% - 90px)`,
                        fontSize: "18px",
                        paddingTop: "5px",
                      }}
                    >
                      <p>Referral :</p>
                      <p>{dashboard?.teamCount}</p>
                    </div>
                  </div>
                  <div
                    className="row col-lg-3 "
                    style={{ margin: "10px auto" }}
                  >
                    <div
                      style={{
                        width: "fit-content",
                        display: "inline-block",
                      }}
                    >
                      <span className="icons">
                        <i class="fa-solid fa-dollar"></i>
                      </span>
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        width: "180px",
                        fontSize: "18px",
                        paddingTop: "5px",
                      }}
                      className="tb"
                    >
                      <p>Referral Biz :</p>
                      <p>
                        {dashboard &&
                          (dashboard?.teamBusiness / 1e18).toFixed(4)}
                      </p>
                      {/* <p>{dashboard.teamBusiness}</p> */}
                    </div>
                  </div>

                  <div className="row col-lg-3" style={{ margin: "10px auto" }}>
                    <div
                      style={{
                        width: "fit-content",
                        display: "inline-block",
                      }}
                    >
                      <span className="icons">
                        <i
                          class="fa-solid fa-user-group"
                          style={{ fontSize: "40px" }}
                        ></i>
                      </span>
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        width: `calc(100% - 90px)`,
                        fontSize: "18px",
                        paddingTop: "5px",
                      }}
                    >
                      <p>Direct Referral :</p>
                      <p>{dashboard && dashboard?.directCount}</p>
                    </div>
                  </div>
                </div>
                <div
                  className="row col-lg-11 userCard"
                  style={{ margin: "auto" }}
                >
                  <div className="row col-lg-3" style={{ margin: "10px auto" }}>
                    <div
                      style={{
                        width: "fit-content",
                        display: "inline-block",
                      }}
                    >
                      <span className="icons">
                        <i
                          class="fa-solid fa-star"
                          style={{ fontSize: "40px" }}
                        ></i>
                      </span>
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        width: `calc(100% - 90px)`,
                        fontSize: "18px",
                        paddingTop: "5px",
                      }}
                    >
                      <p>Rank :</p>
                      <p>
                        {dashboard?.rank === 0 && "0"}
                        {dashboard?.rank === 1 && "Bronze"}
                        {dashboard?.rank === 2 && "Silver"}
                        {dashboard?.rank === 3 && "Gold"}
                        {dashboard?.rank === 4 && "Platinum"}
                        {dashboard?.rank === 5 && "Beryl"}
                        {dashboard?.rank === 6 && "Sapphire"}
                        {dashboard?.rank === 7 && "Ruby"}
                        {dashboard?.rank === 8 && "Emerald"}
                        {dashboard?.rank === 9 && "Diamond"}
                      </p>
                    </div>
                  </div>
                  <div className="row col-lg-3" style={{ margin: "10px auto" }}>
                    <div
                      style={{
                        width: "fit-content",
                        display: "inline-block",
                      }}
                    >
                      <span className="icons">
                        <i
                          class="fa-solid fa-star"
                          style={{ fontSize: "40px" }}
                        ></i>
                      </span>
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        width: `calc(100% - 90px)`,
                        fontSize: "18px",
                        paddingTop: "5px",
                      }}
                    >
                      <p>Rank Bonus :</p>
                      <p>
                        {dashboard && (dashboard?.rankBonus / 1e18).toFixed(1)}
                      </p>
                    </div>
                  </div>

                  <div className="row col-lg-3" style={{ margin: "10px auto" }}>
                    <div
                      style={{
                        width: "fit-content",
                        display: "inline-block",
                      }}
                    >
                      <span className="icons">
                        <i class="fa fa-gift" aria-hidden="true"></i>
                      </span>
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        width: "160px",
                        fontSize: "18px",
                        paddingTop: "5px",
                      }}
                      className="tb"
                    >
                      <p>Pool Bonus :</p>
                      <p>
                        {dashboard?.poolBonus &&
                          (Number(dashboard?.poolBonus) / 1e18)?.toFixed(3)}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="row col-lg-11 userCard"
                  style={{ margin: "auto" }}
                >
                  <div className="row col-lg-3" style={{ margin: "10px auto" }}>
                    <div
                      style={{
                        width: "fit-content",
                        display: "inline-block",
                      }}
                    >
                      <span className="icons">
                        <i
                          class="fa fa-user-plus"
                          aria-hidden="true"
                          style={{ fontSize: "40px" }}
                        ></i>
                      </span>
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: "25px",
                          color: "#dcdf52",
                        }}
                      >
                        70 %
                      </p>
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        width: `calc(100% - 120px)`,
                        fontSize: "18px",
                        paddingTop: "5px",
                      }}
                    >
                      <p>Leg 1 (max) :</p>
                      <p>
                        <input
                          type="range"
                          id="rangeInput"
                          name="rangeInput"
                          min="0"
                          max={maxout}
                          value={maxLeg}
                          className="slider"
                          style={{ width: "150px" }}
                        />
                        {dashboard?.directCount > 0 ? (
                          <>
                            <p
                              style={{ fontSize: "15px", width: "fit-content" }}
                            >
                              {maxLeg?.toFixed(2)} Out of {maxout.toFixed(2)}.
                            </p>
                            <p style={{ fontSize: "15px", width: "100%" }}>
                              {(maxout - maxLeg).toFixed(2)} More to Reach{" "}
                              {level} Rank
                            </p>
                          </>
                        ) : null}
                      </p>
                    </div>
                  </div>
                  <div className="row col-lg-3" style={{ margin: "10px auto" }}>
                    <div
                      style={{
                        width: "fit-content",
                        display: "inline-block",
                      }}
                    >
                      <span className="icons">
                        <i
                          class="fa-solid fa-user-group"
                          style={{ fontSize: "40px" }}
                        ></i>
                      </span>
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: "25px",
                          color: "#dcdf52",
                        }}
                      >
                        30 %
                      </p>
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        width: `calc(100% - 120px)`,
                        fontSize: "18px",
                        paddingTop: "5px",
                      }}
                    >
                      <p>Leg 2 .....</p>
                      <p>
                        <input
                          type="range"
                          id="rangeInput"
                          name="rangeInput"
                          min="0"
                          max={minout}
                          value={minLeg}
                          className="slider"
                          style={{ width: "150px" }}
                        />
                        {dashboard?.directCount > 0 ? (
                          <>
                            <p style={{ width: "100%", fontSize: "15px" }}>
                              {minLeg?.toFixed(1)} Out of {minout.toFixed(2)}.
                            </p>
                            <p style={{ width: "100%", fontSize: "15px" }}>
                              {(minout - minLeg).toFixed(2)} More to reach{" "}
                              {level} Rank
                            </p>
                          </>
                        ) : (
                          ""
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="row col-lg-3" style={{ margin: "10px auto" }}>
                    <div
                      style={{
                        width: "fit-content",
                        display: "inline-block",
                      }}
                    >
                      <span className="icons">
                        <i
                          class="fa-solid fa-gift"
                          style={{ fontSize: "40px" }}
                        ></i>
                      </span>
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        width: `calc(100% - 120px)`,
                        fontSize: "18px",
                        paddingTop: "5px",
                      }}
                    >
                      <p>Reward</p>
                      <p>
                        <input
                          type="range"
                          id="rangeInput"
                          name="rangeInput"
                          min="0"
                          max={maxlimit}
                          value={avlReward + totalRoi}
                          className="slider"
                          style={{ width: "150px" }}
                        />

                        <>
                          <p style={{ width: "100%", fontSize: "15px" }}>
                            Total Reward Limit ={" "}
                            {maxlimit > 0 ? maxlimit?.toFixed(3) : "0"} WYS
                          </p>
                          <p style={{ width: "100%", fontSize: "15px" }}>
                            Reward earned ={" "}
                            {((avlReward ? avlReward : 0) + totalRoi).toFixed(
                              3
                            )}{" "}
                            WYS
                          </p>
                        </>
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="row col-lg-11 userCard"
                  style={{ margin: "auto" }}
                >
                  <div
                    className="row col-lg-3  rs"
                    style={{ margin: "10px auto", height: "fit-content" }}
                  >
                    <div
                      style={{
                        width: "fit-content",
                        display: "inline-block",
                      }}
                    >
                      <span className="icons">
                        <i class="fa-solid fa-circle-dollar-to-slot"></i>
                      </span>
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        width: `calc(100% - 90px)`,
                        fontSize: "18px",
                        paddingTop: "5px",
                      }}
                    >
                      <p>Referral Bonus :</p>
                      <p>
                        {dashboard && (dashboard?.levelBonus / 1e18).toFixed(2)}
                      </p>
                    </div>
                  </div>

                  <div
                    className="row col-lg-3  rs"
                    style={{ margin: "10px auto", height: "fit-content" }}
                  >
                    <div
                      style={{
                        width: "fit-content",
                        display: "inline-block",
                      }}
                    >
                      <span className="icons">
                        <i class="fa-solid fa-circle-dollar-to-slot"></i>
                      </span>
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        width: `calc(100% - 90px)`,
                        fontSize: "18px",
                        paddingTop: "5px",
                      }}
                    >
                      <p>Claimed APY :</p>
                      <p>
                        {dashboard &&
                          (dashboard?.claimedRoi / 1e18).toFixed(2) + " WYS"}
                      </p>
                    </div>
                  </div>

                  <div
                    className="row col-lg-3  rs"
                    style={{ margin: "10px auto", height: "fit-content" }}
                  >
                    <div
                      style={{
                        width: "fit-content",
                        display: "inline-block",
                      }}
                    >
                      <span className="icons">
                        <i class="fa-solid fa-circle-dollar-to-slot"></i>
                      </span>
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        width: `calc(100% - 90px)`,
                        fontSize: "18px",
                        paddingTop: "5px",
                      }}
                    >
                      <p>Claimed Reward :</p>
                      <p>
                        {dashboard &&
                          (dashboard?.withdrawalReward / 1e18).toFixed(2)+" WYS"}
                      </p>
                    </div>
                  </div>

                  {/* <div
                    className="row col-lg-3  rs"
                    style={{
                      margin: "auto",
                      height: "fit-content",
                      opacity: "1",
                    }}
                  >
                    <div
                      style={{
                        width: "fit-content",
                        display: "inline-block",
                      }}
                    >
                      <span className="icons">
                        <i class="fa-solid fa-circle-dollar-to-slot"></i>
                      </span>
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        width: `calc(100% - 90px)`,
                        fontSize: "18px",
                        paddingTop: "5px",
                      }}
                    >
                      <p>Residual Benefit :</p>
                      <p>
                        {dashboard &&
                          (dashboard?.lapseReward / 1e18).toFixed(2)}
                      </p>
                      <button
                        className="btn-success mt-1 mb-2"
                        onClick={transferLapse}
                      >
                        Transfer
                      </button>
                    </div>
                  </div> */}
                </div>
                <div
                  className="row col-lg-11 userCard"
                  style={{ margin: "10px auto" }}
                >
                  <div
                    className="row col-lg-3  rs"
                    style={{ margin: "auto", height: "fit-content" }}
                  >
                    <div
                      style={{
                        width: "fit-content",
                        display: "inline-block",
                      }}
                    >
                      <span className="icons">
                        <i class="fa-solid fa-circle-dollar-to-slot"></i>
                      </span>
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        width: `calc(100% - 90px)`,
                        fontSize: "18px",
                        paddingTop: "5px",
                      }}
                    >
                      <p>Total Claimed Income :</p>
                      <p>
                        {dashboard &&
                          (dashboard?.totalReward / 1e18).toFixed(2)+" WYS"}
                      </p>
                    </div>
                  </div>

                  <div
                    className="row col-lg-3  rs"
                    style={{
                      margin: "10px auto",
                      height: "fit-content",
                      opacity: "1",
                    }}
                  >
                    <div
                      style={{
                        width: "fit-content",
                        display: "inline-block",
                      }}
                    >
                      <span className="icons">
                        <i class="fa-solid fa-circle-dollar-to-slot"></i>
                      </span>
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        width: `calc(100% - 90px)`,
                        fontSize: "18px",
                        paddingTop: "5px",
                      }}
                    >
                      <p>Residual Benefit :</p>
                      <p>
                        {dashboard &&
                          (dashboard?.lapseReward / 1e18).toFixed(2)+" WYS"}
                      </p>
                      <button
                        className="btn-success mt-1 mb-2"
                        onClick={transferLapse}
                      >
                        Transfer
                      </button>
                    </div>
                  </div>

                  <div
                    className="row col-lg-3  rs"
                    style={{
                      margin: "auto",
                      height: "fit-content",
                      opacity: "0",
                    }}
                  ></div>
                </div>
                <div class="modal" tabindex="-1" id="exampleModal">
                  <div class="modal-dialog">
                    <div
                      class="modal-content"
                      style={{
                        background: "#171717",
                      }}
                    >
                      <div class="modal-header">
                        <button
                          type="button"
                          class=" text-white"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          id="close-btn"
                          style={{
                            background: "black",
                            border: "none",
                            outline: "none",
                            cursor: "pointer",
                          }}
                        >
                          X
                        </button>
                      </div>
                      <div
                        className="col-lg-11"
                        style={{
                          borderRadius: "13px",
                          border: "1px solid var(--Gray-700, #313131)",
                          height: "auto",
                          margin: "20px auto",
                          overflow: "auto",
                        }}
                        id="filterTable"
                      >
                        <table
                          style={{
                            width: "100%",
                            color: "white",
                            marginBottom: "10px",
                            minWidth: "400px",
                            overflow: "auto",
                          }}
                        >
                          <thead>
                            <tr>
                              <th>Pool</th>
                              <th>Duration</th>
                              <th>ROI</th>
                            </tr>
                          </thead>

                          <tbody>
                            {usertotalRoi &&
                              usertotalRoi.map((item, index) => {
                                let poolName = "";
                                if (item.planId === 1) {
                                  poolName = "WYS";
                                } else if (item.planId === 2) {
                                  poolName = "WYS:ARB";
                                } else if (item.planId === 3) {
                                  poolName = "WYS:BNB";
                                } else if (item.planId === 4) {
                                  poolName = "WYS:WYZ[50:50]";
                                } else if (item.planId === 5) {
                                  poolName = "WYS:WYZ[70:30]";
                                } else if (item.planId === 6) {
                                  poolName = "fUSDT";
                                }

                                if (item.roi > 0) {
                                  return (
                                    <tr key={index}>
                                      <td>{poolName}</td>
                                      <td>{`${item.duration} Month`}</td>
                                      <td>{item.roi.toFixed(6)}</td>
                                    </tr>
                                  );
                                }
                                return null; // If ROI is not greater than 0, don't render anything
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {selectedTab === "tab3" && (
              <div>
                {" "}
                <div
                  className="row col-lg-10"
                  style={{
                    borderRadius: "13px",
                    border: "1px solid var(--Gray-700, #313131)",
                    height: "auto",
                    margin: "20px auto",
                    overflow: "auto",
                  }}
                  id="filterTable"
                >
                  <table
                    style={{
                      width: "100%",
                      color: "white",
                      marginBottom: "10px",
                      minWidth: "900px",
                      overflowX: "auto",
                    }}
                  >
                    <thead>
                      <tr>
                        <th>User Id</th>
                        <th>User Address</th>
                        <th>WYS Farm </th>
                        {/* <th>Team</th> */}
                        <th>Team Business</th>
                      </tr>
                    </thead>
                    {directUserData &&
                      directUserData?.map((data) => (
                        <tbody>
                          <td>{data.userId}</td>
                          <td>
                            {data.user.slice(0, 3)}...{data.user.slice(-8)}
                          </td>
                          <td>{(data.wysStaked / 1e18).toFixed(2)} WYS </td>
                          {/* <td>{data.teamCount} </td> */}
                          <td>{(data?.teamBusiness / 1e18).toFixed(4)} WYS </td>
                        </tbody>
                      ))}
                  </table>
                  <Pagination
                    count={totalPages}
                    page={currentdirectPage}
                    onChange={handleDirectPageChange}
                    variant="outlined"
                    shape="rounded"
                    color="primary"
                    className="pagination1"
                  />
                </div>
              </div>
            )}
            {selectedTab === "tab4" && (
              <div>
                {" "}
                <div
                  className="row col-lg-10"
                  style={{
                    borderRadius: "13px",
                    border: "1px solid var(--Gray-700, #313131)",
                    height: "auto",
                    margin: "20px auto",
                    overflow: "auto",
                  }}
                  id="filterTable"
                >
                  <table
                    style={{
                      width: "100%",
                      color: "white",
                      marginBottom: "10px",
                      minWidth: "900px",
                    }}
                  >
                    <thead>
                      <tr>
                        <th>User Id</th>
                        <th>User Address</th>
                        <th>Level</th>
                        <th>Rank</th>
                        <th>WYS Farmed</th>
                        <th>Team Business</th>
                        <th>Direct Team</th>
                        {/* <th>Team</th> */}
                        <th>Time</th>
                      </tr>
                    </thead>
                    {team &&
                      team?.map((data) => (
                        <tbody>
                          <td>{data.userId}</td>
                          <td>
                            {data.user.slice(0, 3)}...{data.user.slice(-6)}
                          </td>
                          <td>{data.level}</td>
                          <td>{data.rank}</td>
                          <td>{processWysAmount(data.wysStaked).toFixed(2)}</td>
                          <td>{(data.teamBusiness / 1e18).toFixed(4)}</td>
                          <td>{data.directCount}</td>
                          {/* <td>{data.teamCount}</td> */}
                          <td>{moment(data.timestamp * 1000).fromNow()}</td>
                        </tbody>
                      ))}
                  </table>
                  <Pagination
                    count={totalPages}
                    page={currentTeamPage}
                    onChange={handleTeamPageChange}
                    variant="outlined"
                    shape="rounded"
                    color="primary"
                    className="pagination1"
                  />
                </div>
              </div>
            )}
            {selectedTab === "tab5" && (
              <div>
                {" "}
                <div
                  className="row col-lg-10"
                  style={{
                    borderRadius: "13px",
                    border: "1px solid var(--Gray-700, #313131)",
                    height: "auto",
                    margin: "20px auto",
                    overflow: "auto",
                  }}
                  id="filterTable"
                >
                  <table
                    style={{
                      width: "100%",
                      color: "white",
                      marginBottom: "10px",
                      minWidth: "900px",
                    }}
                  >
                    <thead>
                      <tr>
                        <th>Received By</th>
                        <th>Level</th>
                        <th>Amount</th>
                        <th>Transaction</th>
                        <th>Time</th>
                      </tr>
                    </thead>
                    {levelIncome &&
                      levelIncome?.map((data) => (
                        <tbody>
                          <td>
                            {data.sender.slice(0, 3)}...{data.sender.slice(-6)}
                          </td>
                          <td>{data.level}</td>
                          <td>{(data?.amount / 1e18).toFixed(4)} WYS </td>
                          <td>
                            <a
                              href={`https://wyzthscan.org/tx/${data.txHash}`}
                              style={{ color: "white" }}
                              target="_blank"
                            >
                              {data.txHash.slice(0, 3)}...
                              {data.txHash.slice(-6)}
                            </a>
                          </td>
                          <td>
                            {moment(
                              new Date(data.createdAt).getTime()
                            ).fromNow()}
                          </td>
                        </tbody>
                      ))}
                  </table>
                  <Pagination
                    count={totalPages}
                    page={currentLevelPage}
                    onChange={handleLevelPageChange}
                    variant="outlined"
                    shape="rounded"
                    color="primary"
                    className="pagination1"
                  />
                </div>
              </div>
            )}
            {selectedTab === "tab6" && (
              <div>
                {" "}
                <div
                  className="row col-lg-10"
                  style={{
                    borderRadius: "13px",
                    border: "1px solid var(--Gray-700, #313131)",
                    height: "auto",
                    margin: "20px auto",
                    overflow: "auto",
                  }}
                  id="filterTable"
                >
                  <table
                    style={{
                      width: "100%",
                      color: "white",
                      marginBottom: "10px",
                      minWidth: "900px",
                    }}
                  >
                    <thead>
                      <tr>
                        <th>Harvest</th>
                        <th>Transaction</th>
                        <th>Time</th>
                      </tr>
                    </thead>
                    {claimIncome &&
                      claimIncome?.map((data) => (
                        <tbody>
                          <td>{Number(data.roi / 1e18).toFixed(4)}</td>
                          <td>
                            <a
                              href={`https://wyzthscan.org/tx/${data.txHash}`}
                              className="text-white"
                              target="_blank"
                            >
                              {data.txHash.slice(0, 3)}...
                              {data.txHash.slice(-6)}
                            </a>
                          </td>
                          <td>{moment(data.timestamp * 1000).fromNow()}</td>
                        </tbody>
                      ))}
                  </table>
                  <Pagination
                    count={totalPages}
                    page={currentClaimPage}
                    onChange={handleClaimPageChange}
                    variant="outlined"
                    shape="rounded"
                    color="primary"
                    className="pagination1"
                  />
                </div>
              </div>
            )}
            {selectedTab === "tab7" && (
              <div>
                {" "}
                <div
                  className="row col-lg-10"
                  style={{
                    borderRadius: "13px",
                    border: "1px solid var(--Gray-700, #313131)",
                    height: "auto",
                    margin: "20px auto",
                    overflow: "auto",
                  }}
                  id="filterTable"
                >
                  <table
                    style={{
                      width: "100%",
                      color: "white",
                      marginBottom: "10px",
                      minWidth: "900px",
                    }}
                  >
                    <thead>
                      <tr>
                        <th>Amount</th>
                        {/* <th>Transaction</th> */}
                        <th>Time</th>
                      </tr>
                    </thead>
                    {poolIncomeData &&
                      poolIncomeData?.map((data) => (
                        <tbody>
                          <td>{Number(data.amount / 1e18).toFixed(4)}</td>
                          <td>{moment(data.timestamp * 1000).fromNow()}</td>
                        </tbody>
                      ))}
                  </table>
                  <Pagination
                    count={poolTotalPage}
                    // page={poolTotalPage}
                    onChange={handlePoolPageChange}
                    variant="outlined"
                    shape="rounded"
                    color="primary"
                    className="pagination1"
                  />
                </div>
              </div>
            )}

            {selectedTab === "tab8" && (
              <div>
                {" "}
                <div
                  className="row col-lg-10"
                  style={{
                    borderRadius: "13px",
                    border: "1px solid var(--Gray-700, #313131)",
                    height: "auto",
                    margin: "20px auto",
                    overflow: "auto",
                  }}
                  id="filterTable"
                >
                  <table
                    style={{
                      width: "100%",
                      color: "white",
                      marginBottom: "10px",
                      minWidth: "900px",
                    }}
                  >
                    <thead>
                      <tr>
                        <th>Transfer Residual Amount</th>
                        <th>Total Residual Amount</th>
                        <th>Previous Availaible Reward</th>
                        <th>Availaible Reward(Aftet Residual Transfer)</th>
                        <th>Time</th>
                      </tr>
                    </thead>
                    {lapseRewardHist &&
                      lapseRewardHist?.map((data) => (
                        <tbody>
                          <td>{Number(data.amount / 1e18).toFixed(4)}</td>
                          <td>{Number(data.lapsReward / 1e18).toFixed(4)}</td>
                          <td>
                            {(
                              Number(data.availableReward / 1e18) -
                              Number(data.amount / 1e18)
                            ).toFixed(4)}
                          </td>
                          <td>
                            {Number(data.availableReward / 1e18).toFixed(4)}
                          </td>
                          <td>{moment(data.createdAt).fromNow()}</td>
                        </tbody>
                      ))}
                  </table>
                  <Pagination
                    count={poolTotalPage}
                    // page={poolTotalPage}
                    onChange={handlePoolPageChange}
                    variant="outlined"
                    shape="rounded"
                    color="primary"
                    className="pagination1"
                  />
                </div>
              </div>
            )}

            {selectedTab === "tab9" && (
              <div>
                {" "}
                <div
                  className="row col-lg-10"
                  style={{
                    borderRadius: "13px",
                    border: "1px solid var(--Gray-700, #313131)",
                    height: "auto",
                    margin: "20px auto",
                    overflow: "auto",
                  }}
                  id="filterTable"
                >
                  <table
                    style={{
                      width: "100%",
                      color: "white",
                      marginBottom: "10px",
                      minWidth: "900px",
                    }}
                  >
                    <thead>
                      <tr>
                        <th>Amount</th>
                        <th>Transaction</th>
                        <th>Time</th>
                      </tr>
                    </thead>
                    {claimRewardData &&
                      claimRewardData?.map((data) => (
                        <tbody>
                          <td>{Number(data.amount / 1e18).toFixed(4)}</td>
                          <td>
                            <a
                              href={`https://wyzthscan.org/tx/${data.txHash}`}
                              className="text-white"
                              target="_blank"
                            >
                              {data.txHash.slice(0, 3)}...
                              {data.txHash.slice(-6)}
                            </a>
                          </td>
                          <td>
                            {moment(data.timestamp * 1000).format(
                              "DD-MM-YY hh:mm:ss A"
                            )}
                          </td>
                        </tbody>
                      ))}
                  </table>
                  <Pagination
                    count={claimRewardTotalPage}
                    page={claimRewardPage}
                    onChange={handleClaimRewardPageChange}
                    variant="outlined"
                    shape="rounded"
                    color="primary"
                    className="pagination1"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default User;
